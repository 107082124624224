import { createRouter, createWebHistory } from "vue-router";
import { beforeEnterPartners } from "@/router/navigationGuards";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: "/", redirect: "/parking/sessions" },

    // dashboard
    { path: "/insights", redirect: "/insights/pulse" },
    {
      path: "/insights/pulse",
      name: "Dashboard",
      component: () => import("@/views/Dashboard.vue"),
    },
    {
      path: "/insights/guest-sentiment",
      name: "Guest Sentiment Dashboard",
      component: () => import("@/views/DashboardGuestSentiment.vue"),
    },
    {
      path: "/insights/digital-tips",
      name: "Digital Tips Dashboard",
      component: () => import("@/views/DashboardDigitalTips.vue"),
    },

    // Partners
    {
      path: "/partners",
      name: "Partners",
      component: () => import("@/views/partners/PagePartnerList.vue"),
      beforeEnter: beforeEnterPartners,
    },
    {
      path: "/partners/new",
      name: "Add Partner",
      component: () => import("@/views/partners/PagePartnerNew.vue"),
      beforeEnter: beforeEnterPartners,
    },
    {
      path: "/partners/:partnerId",
      name: "View Partner",
      component: () => import("@/views/partners/PagePartner.vue"),
      beforeEnter: beforeEnterPartners,
    },
    {
      path: "/partners/:partnerId/manage",
      name: "Manage Partner",
      component: () => import("@/views/partners/PagePartnerManage.vue"),
      props: true,
      beforeEnter: beforeEnterPartners,
    },

    // Parking Sessions
    { path: "/parking", redirect: "/parking/sessions" },
    {
      path: "/parking/sessions",
      redirect: "/parking/sessions/list",
      children: [
        {
          path: "list",
          component: () => import("@/views/PageParkingSessions.vue"),
        },
        {
          path: ":sessionId",
          component: () =>
            import("@/views/parking/sessions/PageSessionItem.vue"),
          props: true,
          redirect: { name: "Session Detail" },
          children: [
            {
              path: "detail",
              name: "Session Detail",
              component: () =>
                import("@/views/parking/sessions/PageSessionDetails.vue"),
            },
          ],
        },
      ],
    },

    {
      path: "/parking/conversion",
      component: () =>
        import("@/views/parking/conversion/PageParkingViolations.vue"),
      redirect: { name: "Citations List" },
      children: [
        {
          path: "codes",
          component: () =>
            import("@/views/parking/conversion/PageParkingViolationCodes.vue"),
        },
        {
          path: "/parking/conversion/citations",
          name: "Citations List",
          component: () =>
            import("@/views/parking/conversion/PageParkingCitations.vue"),
        },
        {
          path: "/parking/conversion/citations/:citationId",
          component: () =>
            import("@/views/parking/conversion/PageParkingCitation.vue"),
        },
      ],
    },

    // permits
    {
      path: "/parking/permits",
      redirect: "/parking/permits/list",
      children: [
        {
          path: "list",
          component: () =>
            import("@/views/parking/permits/PageParkingPermitsList.vue"),
        },
        {
          path: ":permitId",
          component: () =>
            import("@/views/parking/permits/PageParkingPermitDetails.vue"),
          props: true,
          redirect: { name: "Space Assignments" },
          children: [
            {
              path: "assignments",
              name: "Space Assignments",
              component: () =>
                import(
                  "@/views/parking/permits/PagePermitSpaceAssignments.vue"
                ),
            },
            {
              path: "invoices",
              name: "Invoices",
              component: () =>
                import("@/views/parking/permits/PagePermitInvoices.vue"),
            },
          ],
        },
      ],
    },

    // audits
    {
      path: "/parking/audits",
      redirect: "/parking/audits/list",
      children: [
        {
          path: "list",
          component: () =>
            import("@/views/parking/audits/PageParkingAuditsList.vue"),
        },
        {
          path: ":auditId",
          component: () =>
            import("@/views/parking/audits/PageParkingAuditDetails.vue"),
        },
      ],
    },

    // Parking Lots
    {
      path: "/parking/lots",
      redirect: "/parking/lots/list",
      children: [
        {
          path: "list",
          component: () =>
            import("@/views/parking/lots/PageParkingLotsList.vue"),
        },
        {
          path: "new",
          component: () => import("@/views/parking/lots/PageParkingLotNew.vue"),
        },
        {
          path: ":lotId",
          component: () => import("@/views/parking/lots/PageParkingLot.vue"),
          props: true,
          redirect: { name: "Location Sessions" },
          children: [
            // {
            //   path: "dashboard",
            //   name: "Location Dashboard",
            //   component: () =>
            //     import("@/views/parking/lots/PageParkingLotDashboard.vue"),
            // },
            {
              path: "sessions",
              name: "Location Sessions",
              component: () =>
                import("@/views/parking/lots/components/LocationSessions.vue"),
            },
            {
              path: "permits",
              name: "Location Permits",
              component: () =>
                import(
                  "@/views/parking/lots/components/LocationPermits/LocationPermits.vue"
                ),
            },
            {
              path: "citations",
              name: "Location Citations",
              component: () =>
                import("@/views/parking/lots/components/LocationCitations.vue"),
            },
            {
              path: "audits",
              name: "Location Audits",
              props: true,
              component: () =>
                import(
                  "@/views/parking/lots/components/LocationAudits/LocationAudits.vue"
                ),
            },
            {
              path: "whitelist",
              name: "Location Whitelist",
              props: true,
              component: () =>
                import(
                  "@/views/parking/lots/components/LocationWhitelist/LocationWhitelist.vue"
                ),
            },
            {
              path: "claims",
              name: "Location Claims",
              props: true,
              component: () =>
                import(
                  "@/views/parking/lots/components/LocationClaims/LocationClaims.vue"
                ),
            },
            {
              path: "reporting",
              name: "Location Reporting",
              redirect: { name: "Location Reports" },
              component: () =>
                import(
                  "@/views/parking/lots/components/LocationReporting/LocationReporting.vue"
                ),
              children: [
                {
                  path: "reports",
                  name: "Location Reports",
                  component: () =>
                    import(
                      "@/views/parking/lots/components/LocationReporting/ReportsList.vue"
                    ),
                },
                {
                  name: "Location Scheduled Reports",
                  path: "scheduled",
                  component: () =>
                    import(
                      "@/views/parking/lots/components/LocationReporting/ScheduledReports.vue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: ":lotId/manage",
          component: () =>
            import("@/views/parking/lots/PageParkingLotManage.vue"),
        },
        {
          path: ":lotId/manage/operations/:type",
          component: () =>
            import("@/views/parking/lots/PageParkingLotManageOperation.vue"),
        },
        {
          path: ":lotId/claims/:claimId",
          props: true,
          component: () =>
            import("@/views/parking/claims/PageLocationClaimDetails.vue"),
          redirect: { name: "Claim Details" },
          children: [
            {
              path: "details",
              name: "Claim Details",
              props: true,
              component: () =>
                import("@/views/parking/claims/components/ClaimDetailsTab.vue"),
            },
            {
              path: "incidents-reported",
              name: "Claim Incidents Reported",
              props: true,
              component: () =>
                import(
                  "@/views/parking/claims/components/ClaimIncidentsReportedTab.vue"
                ),
            },
            {
              path: "vds",
              name: "Claim VDS",
              props: true,
              component: () =>
                import("@/views/parking/claims/components/ClaimVDSTab.vue"),
            },
            {
              path: "manager-recommendations",
              name: "Claim Manager Recommendations",
              props: true,
              component: () =>
                import(
                  "@/views/parking/claims/components/ClaimManagerRecommendationsTab.vue"
                ),
            },
          ],
        },
      ],
    },

    // reporting
    {
      path: "/parking/reporting",
      component: () =>
        import("@/views/parking/reporting/PageParkingReporting.vue"),
      redirect: "/parking/reporting/list",
      children: [
        {
          path: "list",
          component: () =>
            import("@/views/parking/reporting/PageParkingReportingList.vue"),
        },
        {
          path: ":reportName",
          component: () =>
            import("@/views/parking/reporting/PageParkingReportingReport.vue"),
        },
      ],
    },
    // Tools
    { path: "/tools", redirect: "/tools/opt-out" },
    {
      path: "/tools/opt-out",
      component: () => import("@/views/tools/opt-out/PageToolsOptOut.vue"),
      children: [
        {
          path: "new",
          component: () =>
            import("@/views/tools/opt-out/components/PageOptOutNew.vue"),
        },
      ],
    },
    {
      path: "/tools/notices",
      name: "Void Notices",
      component: () => import("@/views/tools/notices/PageToolsNotices.vue"),
    },
  ],
});

export default router;
